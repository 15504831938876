import axios from 'axios'
// import { baseUrl } from './env.js'
import {getToken} from '@/api/auth'
import {removeToken} from './auth'
import {Message, Notification, MessageBox} from 'element-ui'
import router from '../router'
// import { Dialog, Toast } from 'vant'
// import store from '@/store'

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'

const service = axios.create({
    baseURL: "https://api.ai-zhineng.com:24053", // url = base url + request url
    // baseURL: "http://192.168.31.162:24053", // url = base url + request url
    timeout: 10000 // request timeout
})

// 请求拦截器
service.interceptors.request.use(
    config => {
        // 是否需要设置 token
        if (getToken()) {
            config.headers['Authorization'] = getToken() // 让每个请求携带自定义token
        }
        return config
    },
    error => {
        // do something with request error
        console.log(error) // for debug
        return Promise.reject(error)
    }
)

// 响应拦截器
service.interceptors.response.use(
    response => {
        const res = response.data
        if (res.status === -1) {
            Message({
                message: res.msg || 'Error',
                type: "info"
            })
            return Promise.reject(new Error(res.msg || 'Error'))
        } else if (res.status === -2) {
            Message({
                message: res.msg || 'Error',
                type: "error"
            })
            return Promise.reject(new Error(res.msg || 'Error'))
        } else if (res.status === -3) {
            Notification({
                title: '警告',
                message: res.msg,
                type: 'warning',
            })
            return Promise.reject(new Error(res.msg || 'Error'))
        } else if (res.status === -4) {
            Notification({
                title: '错误',
                message: res.msg,
                type: 'error',
            })
            return Promise.reject(new Error(res.msg || 'Error'))
        } else if (res.status === -5) {
            MessageBox.alert(res.msg, "警告", {
                type: "warning"
            })
            return Promise.reject(new Error(res.msg || 'Error'))
        } else if (res.status === -6) {
            MessageBox.alert(res.msg, "错误", {
                type: "error"
            })
            return Promise.reject(new Error(res.msg || 'Error'))
        } else if (res.status === -7) {
            removeToken()
            MessageBox.alert("登录验证失效, 请重新登录", "登录失效", {
                type: "error"
            })
            router.push('/login')
            return Promise.reject(new Error(res.msg || 'Error'))
        } else if (res.status === -8) {
            removeToken()
            MessageBox.alert("权限不足, 请重新登录或联系管理员！", "权限不足", {
                type: "error"
            })
            router.push('/login')
            return Promise.reject(new Error(res.msg || 'Error'))
        } else if (res.status === -9) {
            return Promise.reject(new Error(res.msg || 'Error'))
        } else {
            return res
        }
    },
    error => {
        MessageBox.alert("系统错误，请联系技术支持！ (Code:-1)", "系统错误", {
            type: "error"
        })
        return Promise.reject(error)
    }
)

const api = {
    // 登录
    login: (data) => service({
        url: '/api/v7/login',
        method: 'post',
        data
    }),

    // 主页
    card: (data) => service({
        url: '/api/v7/device_card',
        method: 'get',
        params: data

    }),

    // 设备
    enable_device: (data) => service({
        url: '/api/v7/enable_device',
        method: 'post',
        data
    }),
    // 锁机
    get_lock_info: (data) => service({
        url: '/api/v7/get_lock_info',
        method: 'post',
        data
    }),
    lock_device: (data) => service({
        url: '/api/v7/lock_device',
        method: 'post',
        data
    }),
    // turn_device: (data) => service({ // 改为operator_device
    //     url: '/api/v7/turn_device',
    //     method: 'post',
    //     data
    // }),
    // 操作设备 在启停基础上 加上设备注册取消
    operator_device: (data) => service({
        url: '/api/v7/operator_device',
        method: 'post',
        data
    }),
    offline_charge_record: (data) => service({
        url: '/api/v7/offline_charge_record',
        method: 'post',
        data
    }),
    offline_confirm: (data) => service({
        url: '/api/v7/offline_confirm',
        method: 'post',
        data
    }),
    charge_device: (data) => service({
        url: '/api/v7/charge_device',
        method: 'post',
        data
    }),
    charge_confirm: (data) => service({
        url: '/api/v7/charge_confirm',
        method: 'post',
        data
    }),
    device_list: (data) => service({
        url: '/api/v7/device_list',
        method: 'get',
        params: data
    }),
    get_location: (data) => service({
        url: '/api/v7/get_location',
        method: 'get',
        params: data
    }),
    location_commit: (data) => service({
        url: '/api/v7/location_commit',
        method: 'post',
        data
    }),
    // rename_device: (data) => service({
    //     url: '/api/v7/rename_device',
    //     method: 'post',
    //     data
    // }),
    get_comments_and_addr: (data) => service({
        url: '/api/v7/get_comments_and_addr',
        method: 'post',
        data
    }),
    add_offline_device: (data) => service({
        url: '/api/v7/add_offline_device',
        method: 'post',
        data
    }),
    edit_device_info: (data) => service({
        url: '/api/v7/edit_device_info',
        method: 'post',
        data
    }),
    // device_detail: (data) => service({
    //     url: '/api/v1/device_detail', //TODO v5
    //     method: 'get',
    //     params: data
    // }),
    username_list: () => service({
        url: '/api/v7/username_list',
        method: 'get',
    }),
    // sel_kids: () => service({
    //     url: '/api/v5/sel_kids',
    //     method: 'get',
    // }),
    device_owner: (data) => service({
        url: '/api/v7/device_owner',
        method: 'get',
        params: data

    }),
    bind_or_not: (data) => service({
        url: '/api/v7/bind_or_not',
        method: 'post',
        data
    }),
    delete_device: (data) => service({
        url: '/api/v7/delete_device',
        method: 'post',
        data
    }),
    device_order_export: (data) => service({
        url: '/api/v7/device_order_export',
        method: 'post',
        data
    }),
    charge_order_export: (data) => service({ // + 2024年1月29日
        url: '/api/v7/charge_order_export',
        method: 'post',
        data
    }),
    filter_verify: (data) => service({
        url: '/api/v7/filter_verify',
        method: 'post',
        data
    }),
    // Coupon
    coupon_list: (data) => service({
        url: '/api/v7/coupon_list',
        method: 'get',
        params: data
    }),
    create_coupon: (data) => service({
        url: '/api/v7/create_coupon',
        method: 'post',
        data
    }),
    create_multi_coupon: (data) => service({
        url: '/api/v7/create_multi_coupon',
        method: 'post',
        data
    }),
    delete_coupon: (data) => service({
        url: '/api/v7/delete_coupon',
        method: 'post',
        data
    }),
    device_config: (data) => service({
        url: '/api/v7/device_config',
        method: 'get',
        params: data
    }),
    device_config_commit: (data) => service({
        url: '/api/v7/device_config_commit',
        method: 'post',
        data
    }),
    // 服务
    service_list: (data) => service({
        url: '/api/v7/service_list',
        method: 'get',
        params: data
    }),
    service_all_list: (data) => service({
        url: '/api/v7/service_all_list',
        method: 'get',
        params: data
    }),
    create_server_order: (data) => service({
        url: '/api/v7/create_server_order',
        method: 'post',
        data
    }),
    service_detail: (data) => service({
        url: '/api/v7/service_detail',
        method: 'post',
        data
    }),
    service_commit: (data) => service({
        url: '/api/v7/service_commit',
        method: 'post',
        data
    }),
    // 用户
    update_user_info: (data) => service({
        url: '/api/v7/update_user_info',
        method: 'post',
        data
    }),
    delete_user: (data) => service({
        url: '/api/v7/delete_user',
        method: 'post',
        data
    }),
    create_user: (data) => service({
        url: '/api/v7/create_user',
        method: 'post',
        data
    }),
    user_list: (data) => service({
        url: '/api/v7/user_list',
        method: 'get',
        params: data
    }),
    // charge_user: (data) => service({
    //     url: '/api/v7/charge_user',
    //     method: 'post',
    //     data
    // }),
    change_password: (data) => service({
        url: '/api/v7/change_password',
        method: 'post',
        data
    }),
    change_password_with_old: (data) => service({
        url: '/api/v7/change_password_with_old',
        method: 'post',
        data
    }),
    change_op_password: (data) => service({
        url: '/api/v7/change_op_password',
        method: 'post',
        data
    }),
    // register: (data) => service({
    //     url: '/api/v1/register', //TODO v5
    //     method: 'post',
    //     data
    // }),

    // 权限组
    pg_query: (data) => service({
        url: '/api/v7/pg_query',
        method: 'post',
        data
    }),
    pg_list: () => service({
        url: '/api/v7/pg_list',
        method: 'get',
    }),

}

export default api
