import axios from 'axios'
// import { baseUrl } from './env.js'
// import {getToken} from '@/api/auth'
// import {removeToken} from './auth'
import {Message, Notification, MessageBox} from 'element-ui'
// import router from '../router'
// import { Dialog, Toast } from 'vant'
// import store from '@/store'

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'

const service = axios.create({
    baseURL: "https://log.ai-zhineng.com:24777", // url = base url + request url
    // baseURL: "http://192.168.31.162:24053", // url = base url + request url
    timeout: 15000 // request timeout
})

// 请求拦截器
service.interceptors.request.use(
    config => {
        // 是否需要设置 token
        // if (getToken()) {
        //     config.headers['Authorization'] = getToken() // 让每个请求携带自定义token
        // }
        return config
    },
    error => {
        // do something with request error
        console.log(error) // for debug
        return Promise.reject(error)
    }
)

// 响应拦截器
service.interceptors.response.use(
    response => {
        const res = response.data
        if (res.status === -1) {
            Message({
                message: res.msg || 'Error',
                type: "info"
            })
            return Promise.reject(new Error(res.msg || 'Error'))
        } else if (res.status === -2) {
            Message({
                message: res.msg || 'Error',
                type: "error"
            })
            return Promise.reject(new Error(res.msg || 'Error'))
        } else if (res.status === -3) {
            Notification({
                title: '警告',
                message: res.msg,
                type: 'warning',
            })
            return Promise.reject(new Error(res.msg || 'Error'))
        } else if (res.status === -4) {
            Notification({
                title: '错误',
                message: res.msg,
                type: 'error',
            })
            return Promise.reject(new Error(res.msg || 'Error'))
        } else if (res.status === -5) {
            MessageBox.alert(res.msg, "警告", {
                type: "warning"
            })
            return Promise.reject(new Error(res.msg || 'Error'))
        } else if (res.status === -6) {
            MessageBox.alert(res.msg, "错误", {
                type: "error"
            })
            return Promise.reject(new Error(res.msg || 'Error'))
        // } else if (res.status === -7) {
        //     removeToken()
        //     MessageBox.alert("登录验证失效, 请重新登录", "登录失效", {
        //         type: "error"
        //     })
        //     router.push('/login')
        //     return Promise.reject(new Error(res.msg || 'Error'))
        // } else if (res.status === -8) {
        //     removeToken()
        //     MessageBox.alert("权限不足, 请重新登录或联系管理员！", "权限不足", {
        //         type: "error"
        //     })
        //     router.push('/login')
        //     return Promise.reject(new Error(res.msg || 'Error'))
        } else if (res.status === -9) {
            return Promise.reject(new Error(res.msg || 'Error'))
        } else {
            return res
        }
    },
    error => {
        MessageBox.alert("系统错误，请联系技术支持！ (Code:-1)", "系统错误", {
            type: "error"
        })
        return Promise.reject(error)
    }
)

const api_log = {
    // History
    heat_pulse: (data) => service({
        url: '/open/x1/heat_pulse',
        method: 'get',
        params: data
    }),
}

export default api_log
